<template>
  <section class="app-header">
    <img src="@/assets/logo.png" />
    <div class="title">{{ title }}</div>

    <template v-if="hasToken">
      <a @click="handleLogout">
        <div class="desc">
          <span>{{ userName }}</span>
        </div>
        <div class="desc">
          <el-avatar :size="35" icon="el-icon-user-solid" style="margin-top:7px;"></el-avatar>
        </div>
      </a>
    </template>

  </section>
</template>

<script>
  import {
    checkPackage
  } from "@/api/package"
  export default {
    name: "AppHeader",
    data() {
      return {
        title: process.env.VUE_APP_TITLE,
        timer: null,
        notify: null
      }
    },
    computed: {
      userName() {
        return this.$store.getters.username;
      },
      hasToken() {
        return this.$store.getters.token;
      }
    },
    watch: {
      hasToken(val) {
        clearTimeout(this.timer);

        if (val)
          this.checkPackage()
        else if (this.notify)
          this.notify.close()
      }
    },
    mounted() {
      console.log(process.env)

      clearTimeout(this.timer);
      this.checkPackage()
    },
    beforeUnmount() {
      clearTimeout(this.timer);
    },
    methods: {
      checkPackage() {
        var that = this

        if (!that.hasToken) return

        checkPackage().then(res => {
          if (res.success && res.message) {
            if (that.notify)
              that.notify.close()

            that.notify = that.$notify({
              title: '库存警报',
              message: res.message,
              duration: 0,
              type: 'warning',
              position: 'bottom-right',
              offset: 50,
              onClose() {
                that.timer = setTimeout(that.checkPackage, 1000 * 60 * 5);
              }
            })
          } else {
            that.timer = setTimeout(that.checkPackage, 1000 * 60 * 5);
          }
        })
      },
      handleLogout() {
        this.$confirm("确定要退出登录吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
          .then(() => {
            this.$store.dispatch("Logout").then(() => {
              this.$router.push("login");
            });
          })
          .catch(() => {});
      }
    }
  };
</script>

<style lang="scss" scoped>
  .app-header {
    height: 50px;
    color: #fff;
    background-color: #000;

    &:after {
      clear: both;
    }

    img {
      float: left;
      margin: 10px;
      height: 30px;
    }

    .title {
      float: left;
      font-size: 20px;
      line-height: 50px;
    }

    a,
    a:focus,
    a:hover {
      cursor: pointer;
      color: inherit;
      text-decoration: none;
    }

    .desc {
      float: right;
      font-size: 16px;
      line-height: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
</style>
