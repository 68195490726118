import request from '@/utils/request'

export function getPackageList(distributorId, skip, take) {
  return request({
    url: '/Package/GetPackageList',
    method: 'post',
    params: {
      distributorId,
      skip,
      take
    }
  })
}

export function upsertPackage(id, distributorId, count, kind) {
  return request({
    url: '/Package/UpsertPackage',
    method: 'post',
    params: {
      id,
      distributorId,
      count,
      kind
    }
  })
}

export function getPackageDetail(id) {
  return request({
    url: '/Package/GetPackageDetail',
    method: 'post',
    params: {
      id
    }
  })
}

export function checkPackage() {
  return request({
    url: '/Package/CheckPackage',
    method: 'post'
  })
}
