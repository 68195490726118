<template>
  <div class="app-layout">
    <app-header />
    <app-main />
    <app-footer />
  </div>
</template>

<script>
  import {
    AppHeader,
    AppMain,
    AppFooter
  } from "./components";

  export default {
    name: "AppLayout",
    components: {
      AppHeader,
      AppMain,
      AppFooter
    }
  };
</script>

<style lang="scss" scoped>
  .app-layout {
    position: relative;
    min-height: 100%;
    width: 100%;
    min-width: 1200px;
  }
</style>
