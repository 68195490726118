<template>
  <section class="app-main">
    <router-view v-slot="{ Component }" class="main-container">
      <component :is="Component" />
    </router-view>
  </section>
</template>

<script>
  export default {
    name: 'SunriseMain',
    computed: {
      hasToken() {
        return this.$store.getters.token
      }
    }
  }
</script>

<style lang="scss" scoped>
  .app-main {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh);
    background-color: #ffffff;

    .main-container {
      z-index: 0;
      overflow: hidden;
      flex-grow: 1;
    }
  }
</style>
