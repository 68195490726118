<template>
  <el-submenu v-if="nav.children" :index="index">
    <template #title>
      <i :class="nav.icon"></i>
      <span>{{ nav.label }}</span>
    </template>
    <menu-item v-for="(n,i) in nav.children" :key="n" :nav="n" :index="index+'-'+(i+1)" />
  </el-submenu>
  <el-menu-item v-else :index="nav.path">
    <template #title>
      <i :class="nav.icon"></i>
      <span>{{ nav.label }}</span>
    </template>
  </el-menu-item>
</template>

<script>
  export default {
    name: "MenuItem",
    props: {
      nav: {
        type: Object,
        default: function() {
          return {}
        },
      },
      index: String
    }
  }
</script>
