<template>
  <section class="app-footer">
    <div class="copyright">Copy right@</div>
  </section>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped>
.app-footer {
  height: 50px;
  color: #fff;
  background-color: #000;
}
.app-footer .copyright {
  font-size: 14px;
  margin-left: 10px;
  line-height: 50px;
}
</style>
