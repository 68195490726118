<template>
  <section class="app-main">
    <app-navbar v-if="hasToken" />
    <router-view v-slot="{ Component }" class="main-container">
      <transition mode="out-in" name="fade-transform">
        <component :is="Component" />
      </transition>
    </router-view>
  </section>
</template>

<script>
  import AppNavbar from './AppNavbar'

  export default {
    name: 'AppMain',
    components: {
      AppNavbar
    },
    computed: {
      hasToken() {
        return this.$store.getters.token
      }
    }
  }
</script>

<style lang="scss" scoped>
  .app-main {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 100px);

    .main-container {
      z-index: 0;
      overflow: hidden;
      flex-grow: 1;
      padding: 20px;
      background-color: #f9f9f9;
    }
  }

  /*fade-transform*/
  .fade-transform-leave-active,
  .fade-transform-enter-active {
    transition: all 0.5s;
  }

  .fade-transform-enter-from {
    opacity: 0;
    transform: translateX(-20px);
  }

  .fade-transform-leave-to {
    opacity: 0;
    transform: translateX(-20px);
  }
</style>
