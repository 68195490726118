<template>
  <div class="nav-container">
    <el-menu :uniqueOpened="true" :router="true" :default-active="$route.path" class="el-menu-vertical-demo"
      background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
      <menu-item v-for="(n,i) in nav" :key="n" :nav="n" :index="(i+1)+''" />
    </el-menu>
  </div>
</template>

<script>
  import nav from '../../../router/nav'
  import MenuItem from './MenuItem'

  export default {
    name: "AppNavbar",
    data() {
      return {
        nav: nav
      }
    },
    components: {
      MenuItem
    }
  };
</script>

<style lang="scss" scoped>
  .nav-container {
    z-index: 1;
    min-width: 250px;
    width: 250px;
    background-color: #545c64;
    border-right: solid 1px #e6e6e6;

    .el-menu {
      border: 0;
    }
  }
</style>
